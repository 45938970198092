import { widget } from "./widget"
import { feedbackFormSubmit } from "./feedbackForm"
import { connectFormSubmit } from "./connectForm"
import { langs } from './langs';
import { toggleClass } from './utils/toggleClass'
import { gtag } from './gtag';
import { init } from "./init";
import { crossborder } from "./crossborder";


export const APP = function () {
    init();
    document.addEventListener("DOMContentLoaded", function () {
        widget();
        feedbackFormSubmit();
        connectFormSubmit();
        langs();
        gtag();
        crossborder();
        const yearPlace = document.getElementById("year");
        if (yearPlace) {
            yearPlace.innerHTML = new Date().getFullYear();
        }

        // menu
        const navbarToggler = document.querySelector(".navbar-toggler");
        const NavbarLinks = document.querySelector("ul.NavbarLinks");

        if (navbarToggler) {
            navbarToggler.addEventListener("click", function (event) {
                toggleClass(NavbarLinks, ["NavLinksOpen", "NavLinksClose"]);
            });
        }
    })
};
