export const widget = () => {
	const doPayment = () => {
		const time = new Date().getTime();
		const id = 'id' + Math.random().toString(8).slice(2).substr(0, 6);
		const url = 'https://ecg.test.upc.ua/go/pay';

		const config = {
			Version: 1,
			MerchantID: '1753545',
			TerminalID: 'E7881545',
			locale: 'en',
			Signature: '',
			Currency: 980,
			TotalAmount: 100,
			PurchaseTime: time,
			OrderID: id,
			PurchaseDesc: 'Подарочный набор 2'
		};

		const form = document.createElement('form');
		form.setAttribute('action', url);
		form.setAttribute('method', 'POST');
		form.setAttribute('id', 'payment_form');
		form.setAttribute('target', '_blank');

		let formInnerHtml = `<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`;

		for (const key in config) {
			if (config.hasOwnProperty(key)) {
				const element = config[key];
				formInnerHtml += `<input name="${key}" type="hidden" value="${element}" />`;
			}
		}

		formInnerHtml += '<input type="submit"/>';
		form.innerHTML = formInnerHtml;
		document.body.appendChild(form);
		form.submit();
	};
	const widgetPayment = () => {
		const id = 'id' + Math.random().toString(8).slice(2).substr(0, 6);
		UpcPayment({
			url: 'https://ecg.test.upc.ua/go/pay',
			payment: {
				Version: 1,
				MerchantID: "1753545",
				TerminalID: "E7881545",
				locale: "ru",
				Signature: "Signature",
				Currency: 980,
				TotalAmount: 100,
				PurchaseTime: new Date().getTime(),
				OrderID: id,
				PurchaseDesc: 'Подарочный набор 2'
			},
			options: {
				modal: true,
				modalHeight: '600px',
				modalWidth: '700px'
			}
		})
	}

	const widgetSubmit = document.getElementById('widgetSubmit');
	const widgetModalSubmit = document.getElementById('widgetModalSubmit');

	if (widgetModalSubmit) {
		widgetModalSubmit.addEventListener('click', function (e) {
			e.preventDefault();
			widgetPayment();
		})
	}

	if (widgetSubmit) {
		widgetSubmit.addEventListener('click', function (event) {
			event.preventDefault();
			doPayment();
		});
	}
};
