import { LocalStorageProvider } from "./localstorage";
import { LocaleName, EmailAddres } from "./consts";

const locale = LocalStorageProvider.get(LocaleName);

export const connectFormSubmit = () => {
  const connectForm = document.getElementById("connectForm");
  if (!connectForm) return false;

  const inputs = connectForm.querySelectorAll("input, textarea");

  for (let index = 0; index < inputs.length; index++) {
    const element = inputs[index];
    element.addEventListener("change", function (event) {
      const nextErrorEl = element.nextElementSibling;
      if (nextErrorEl.classList.contains("form-error")) {
        if (!element.validity.valid) {
          nextErrorEl.classList.remove("hidden");
        } else {
          nextErrorEl.classList.add("hidden");
        }
      }
    });
  }

  connectForm.addEventListener("submit", function (event) {
    event.preventDefault();

    let isValid = true;

    for (let index = 0; index < inputs.length; index++) {
      const element = inputs[index];
      if (!element.checkValidity()) {
        isValid = false;
      }
    }
    if (!isValid) return false;

    const fullName = connectForm.querySelector('[name="fullName"]').value;
    const email = connectForm.querySelector('[name="email"]').value;
    const phone = connectForm.querySelector('[name="phone"]').value;
    const shopName = connectForm.querySelector('[name="shopName"]').value;

    const emailFormBody = encodeURIComponent(
      `Name: ${fullName};
            E-mail address: ${email};
            Phone: ${phone}
            Store name: ${shopName}`
    );

    let link = `mailto:${EmailAddres}?subject=Connect&body=` + emailFormBody;
    window.open(link, "_blank");
    window.location.replace(location.origin + "/success");
  });
};
