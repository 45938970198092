import {LocalStorageProvider} from "./localstorage";
import {LocaleName} from "./consts";

export const crossborder = () => {
    const locale = LocalStorageProvider.get(LocaleName);
    if (typeof card2card !== "undefined" && document.getElementById('card2card')) {
        var widget = card2card("card2card", 'https://secure.upc.ua/mt/p2p/init/2149',
            {
                height: 720,
                width: 470,
                frameborder: 0,
                style: "max-width: 99%;"
            }, locale || "uk")
    }
}
