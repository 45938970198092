import {DefaultLang, LocaleName} from './consts';
import { LocalStorageProvider } from './localstorage';
import { trim } from './helpers';
import {basePath} from "./utils/base-path";

export const init = ()=> {
	const locale = LocalStorageProvider.get(LocaleName);
	const languages = [ 'uk', 'en' ];

	if (!locale) {
		const browserLocale = navigator.language;
		if (~languages.indexOf(browserLocale)) {
			LocalStorageProvider.set(LocaleName, browserLocale);
			const pathname = location.pathname.replace(/^(\/en\/|\/uk\/)/, '');

			const base = basePath(browserLocale);
			const redirectToLocale = `${base}/${trim(pathname, '/')}`;
			window.location.replace(redirectToLocale);
		} else {
			LocalStorageProvider.set(LocaleName, 'uk');
			const pathname = location.pathname.replace(/^(\/en\/|\/uk\/)/, '');
			const base = basePath('uk');
			const redirectToLocale = `${base}/${trim(pathname, '/')}`;
			window.location.replace(redirectToLocale);
		}
	} else if (~languages.indexOf(locale) && !~location.pathname.indexOf('/' + locale + '/') && locale !== DefaultLang ) {
		const pathname = location.pathname.replace(/^(\/en\/|\/uk\/)/, '');
		const base = basePath(locale);
		const redirectToLocale = `${base}/${trim(pathname, '/')}`;
		window.location.replace(redirectToLocale);
	}

}
