export class LocalStorageProvider {
	static get(name) {
		const item = localStorage.getItem(name);
		try {
			return JSON.parse(item);
		} catch (error) {
			return item;
		}
	}

	static set(name, item) {
		if (typeof item === 'object') {
			localStorage.setItem(name, JSON.stringify(item));
		} else {
			localStorage.setItem(name, String(item));
		}
	}

	static delete(name) {
		localStorage.removeItem(name);
	}
}
