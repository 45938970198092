import {trim} from "./helpers";
import {LocalStorageProvider} from "./localstorage";
import {LocaleName, DefaultLang} from "./consts";
import {toggleClass} from './utils/toggleClass'
import {basePath} from "./utils/base-path";

export const langs = () => {
    const languageBtn = document.querySelector(".language")
    if (languageBtn) {
        const languageMenu = document.querySelector(".language-menu")

        const locales = ["UK", "EN"]

        let localeLinks = ""

        locales.forEach(currentValue => {
            localeLinks += `<div class="language-list" data-get-locale="${currentValue.toLowerCase()}" role="button">${currentValue} </div>`
        })

        languageMenu.innerHTML = localeLinks

        const localesLinks = document.querySelectorAll("[data-get-locale]")

        for (let index = 0; index < localesLinks.length; index++) {
            const element = localesLinks[index]
            element.addEventListener("click", function (event) {
                const toLang = element.getAttribute("data-get-locale")
                const pathname = location.pathname.replace(/^(\/en\/|\/uk\/)/, "")

                const base = basePath(toLang);

                const redirectToLocale = `${base}/${trim(
                    pathname, "/"
                )}`

                LocalStorageProvider.set(LocaleName, toLang)
                window.location.replace(redirectToLocale)
            })
        }

        languageBtn.addEventListener("click", function (event) {
            toggleClass(languageMenu, ["languageMenuClose", "languageMenuOpen"])
        })
    }
}
